@import url('https://fonts.googleapis.com/css?family=Lato:300,400,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Nunito:ital,wght@1,600&display=swap');
 :root {
    --fontSG: Lato;
    --fontLato: Lato;
    --fontLato:Lato;
    --fontLato: Lato;
    --fontNunitosans: Nunito Sans;
    --fontNunito: Nunito;
    --fontMont:Lato;
    --font-weightLight: 300;
    --font-weightRegular: 400;
    --font-weightSemiBold: 600;
    --font-weightBold: 700;
    --font-weightBlack: 900;

}
body.modal-open-noscroll{
    padding-right: 0!important;
    overflow: hidden;
}
.form-control:focus{
    font-family: var(--fontNunitosans);
    box-shadow: none !important;
    outline: none !important;
}
.dialog-centered{
    margin-top:9%;
}
body { padding-right: 0 !important }
body,
html {
    font-family: 'Lato','Prompt','Lato', sans-serif;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
    height: 100%;
}
b, strong{
    font-weight: bold !important;
}
p{
    font-family: var(--fontLato);
    color: #484555 !important;
}
h6{
    font-family: var(--fontLato);
    font-weight: var(--font-weightRegular);
    font-size: 16px !important;
    color: #010036 !important;
}
.reading-label {
    margin-top: -70px;
}
app-root {
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
    display: block;
}
.reading-block{
    margin-top:-7px;
}
.logo{
        max-width: 154px;
    }

body:not(.keyboardUser) *:focus {
    outline: none;
}

.is-invalid {
   border-color: #FD5860 !important;
    box-shadow:none;
    margin-bottom: 0px !important;
    border-width: 1px !important;
}

.errortxt {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular);
    color: #FD5860 !important;
    font-size: 12px !important;
}

.invalid-feedback,
.text-danger {
   font-family: var(--fontNunitosans);
font-size: 12px !important;
line-height: 18px;
margin:10px 0px !important;
text-align: left;
color: #fd5860 !important;
    font-weight: var( --font-weightRegular);
}

.disabled-btn {
    opacity: 25% !important;
}

.sg-font-m-l {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular) !important;
}

.sg-font-m-r {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular) !important;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 11px !important;
    border-radius: 50%;
    background: white;
    letter-spacing: 0;
    color: darkgrey;
    padding-left: 12.5px;
    filter: drop-shadow(0 10px 20px rgba(153, 153, 153, .2));
    padding-top: 9px;
    width: 30px!important;
    height: 28px!important;
    line-height: 1;
    text-transform: none !important;
}

.swiper-button-prev {
    background-image: url(https://assets.ooki.io/assetsImage/fav_left.svg) !important;
    width: 36px !important;
    height: 53px !important;
    background-size: 100px 100px !important;
}
.swiper-button-next{
    background-image: url(https://assets.ooki.io/assetsImage/fav_right.svg) !important;
    width: 36px !important;
    height: 53px !important;
    background-size: 100px 100px !important;
}
.sg-font-m-m {
    font-family: var(--fontLato);
    font-weight: var( --font-weightRegular) !important;
}

.sg-font-s-b {
    font-family: var(--fontLato);
    font-weight: var( --font-weightSemiBold) !important;
    /*600*/
}

.sg-font-m-b {
    font-family: var(--fontLato);
    font-weight: var( --font-weightSemiBold) !important;
    /*700*/
}

.sg-font-m-ex-b {
    font-family: var(--fontLato);
    font-weight: var( --font-weightSemiBold)!important;
    /*800*/
}


/*text color */

.sg-txt-steel-white {
    color: #ffffff !important;
}

.sg-txt-steel-blue {
    color: #2E4D71;
}

.sg-txt-silver {
    color: #999999;
}

.sg-txt-gry-silver {
    color: #666666;
}

.sg-txt-gry-silverb {
    color: #6B6B6B;
}

.sg-txt-green {
    color: #5db90a;
}

.sg-txt-orangee {
    color: #FF7043;
}

.sg-txt-gry {
    color: #E7E7E4;
}

.sg-txt-light-black {
    color: #494d52;
}

.sg-txt-dark-black {
    color: #272b35
}

.sg-txt-red {
    color: #b90a0a;
}

.sg-txt-orange {
    color: #EE9A07;
}

.sg-txt-grey {
    color: #2E4D71;
}

.sg-txt-lgrey {
    color: #999999;
}

.sg-bg-sky-dblue {
    color: #4DB1F9;
}

.sg-bg-sky-de {
    color: #bebebe;
}

.sg-bg-sky-darkk {
    color: #333333;
}


/* background color */

.sg-bg-green {
    background-color: #5db90a;
}

.sg-bg-sky {
    background-color: #56A9FD;
}

.sg-bg-dark-blue {
    color: #2E4D71;
}

.sg-bg-dark-m-grey {
    color: #6b6b6b;
}

.theme-green .bs-datepicker-head {
    background-color: #50A8FC !important;
}
/* font size */

.sg-font-size-10 {
    font-size: 10px !important;
}

.sg-font-size-11 {
    font-size: 11px !important;
}

.sg-font-size-12 {
    font-size: 12px !important;
}

.sg-font-size-13 {
    font-size: 13px !important;
}

.sg-font-size-14 {
    font-size: 14px !important;
}

.sg-font-size-15 {
    font-size: 15px !important;
}

.sg-font-size-16 {
    font-size: 16px !important;
}

.sg-font-size-17 {
    font-size: 17px !important;
}

.sg-font-size-18 {
    font-size: 18px !important;
}

.sg-font-size-20 {
    font-size: 20px !important;
}

.sg-font-size-22 {
    font-size: 22px !important;
}

.sg-font-size-24 {
    font-size: 24px !important;
}

.sg-font-size-26 {
    font-size: 26px !important;
}

.sg-font-size-30 {
    font-size: 30px !important;
}

.sg-font-size-33 {
    font-size: 33px !important;
}

.sg-font-size-35 {
    font-size: 35px !important;
}


/*End latest UI kit css */


/* container */

.sg-container {
    max-width: 1920px;
    height: auto;
    margin: 0 auto;
}

.sg-container-shadow {
    box-shadow: 0 0 14px #e6e6e6;
}

.sg-container-bottom-green {
    border-bottom: 5px #BEE418 solid;
}

@media (max-width: 991px) {
    .swiper-button-next:after, .swiper-button-prev:after {
        font-family: swiper-icons;
        font-size: 0px !important;
        border-radius: 0%;
        background: transparent;
        letter-spacing: 0;
        color: darkgrey;
        padding-left: 0px;
        filter: none;
        padding-top: 0px;
        width: 0px!important;
        height: 0px!important;
        line-height: 1;
        text-transform: none !important;
    }
    .sg-container-shadow {
        box-shadow: none;
    }
    .swiper-button-prev {
        background-image: none !important;
    }
    .swiper-button-next {
        background-image: none !important;
    }
    .sg-container-bottom-green {
        border: 0;
    }
    .wrapper-footer-sitky {
        padding-top: 90px !important;
    }
}


/* page-title-wrapper */

.sg-page-title-txt {
    font-family: var(--fontLato);
    font-weight: var(--font-weightRegular);
    font-size: 36px;
    color: #2E4D71;
}

.sg-page-title-wrapper {
    padding-bottom: 22px;
    text-align: center;
}


/* button 3 */

.sg-btn-yellow-green {
    border: 2px solid #bde517;
    color: #272b35;
    background-color: #bde517;
    font-family: var(--fontLato);
    font-weight: var(--font-weightRegular);
    font-size: 14px;
    border-radius: 50px;
    height: 55px !important;
}

@media (max-width: 991px) {
    .sg-btn-yellow-green {
        padding: 16px 65px 16px 65px;
        font-size: 12px;
    }
}

@media(max-width:768px) {
    .sg-btn-yellow-green {
        padding: 11px 46px 11px 46px;
        font-size: 12px;
    }
}

.sg-btn-yellow-green:hover {
    border: 2px solid #5DB90A;
    color: #ffffff;
    background-color: #5DB90A;
}

:focus {
    outline: none;
}

select:focus,
button:focus {
    outline: none;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cs-danger-mark {
    color: #D69885 !important;
}

.active-sort-col {
    color: #5DB90A !important;
    position: absolute;
    z-index: 1;
    background: #ffffff;
    top: 1px;
    height: 20px;
}

.disable-select {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cccccc !important;
}

/* .custome-select {
    padding: 7px 30px 7px 10px !important;
} */

.key-details-btn:hover {
    box-shadow: 0 0 4px 0px #56A9FD;
}

.view-details-btn:hover {
    background-color: #218cf8 !important;
    color: #fff !important;
    box-shadow: 0 0 4px 0px #1b8af8 !important;
}

#btn-get-quote:hover {
    background-color: #218cf8 !important;
    color: #ffffff;
    border: 0 solid #56A9FD;
    box-shadow: 0 0 3px #56A9FD;
}

.btn-apply:hover {
    background-color: #218cf8 !important;
}

.btn-key-fact:hover {
    background-color: #56A9FD !important;
    color: #fff !important;
    border: 2px solid #56A9FD;
}

#btn-conf:hover {
    background-color: #218cf8 !important;
}

.nav-link:hover {
    box-shadow: 0 0 3px #b1b1b1 !important;
}

.cba:hover {
    background-color: #218cf8 !important;
    filter: drop-shadow(1px 4px 8px #9AC9FA);
    cursor: pointer !important;
}

.disputebutton:hover {
    background: #cfcfcf !important;
}

.sg-btn-yellow-green:hover {
    background-color: #4ca000 !important;
}

.disputebutton:hover {
    background: #cfcfcf !important;
}

.saveinfobuttonn:hover {
    background: #5db90a !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

.saveinfobutton:hover {
    background-color: #4ca000 !important;
}

#sg-btn-reset:hover {
    background-color: #4ca000 !important;
}

.sg-btn-yellow-green:hover {
    background-color: #4ca000 !important;
}

#sg-btn-send-link:hover {
    background-color: #4ca000 !important;
}

#btn-proceed-next-step:hover {
    background-color: #4ca000 !important;
}

#btn-try-again:hover {
    background-color: #4ca000 !important;
}

#app-sign-up-identity-check-identification-failed:hover {
    background-color: #4ca000 !important;
}

#loaderimg {
    display: block;
    width: 100%;
    height: 100vh;
    text-align: center;
}

#loaderimg img {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

#loaderimg p {
    margin: 0 auto;
    position: absolute;
    top: 60%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
    cursor: auto;
    pointer-events: none;
}

.wrapper-footer-sitky {
    min-height: 100%;
    height: auto !important;
    margin: 0 auto -77px;
    padding-top: 95px;
    background: #FDFDFD;
}

.footer,
.dff {
    height:115px;
}
.form-control{
    color: #484555 !important;
    font-size:14px !important;
}
::-webkit-input-placeholder { /* Edge */
    color: #999 !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer */
    color: #999 !important;
  }
  
  ::placeholder {
    color: #999 !important;
  }
select:first {
    color: #999 !important;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
    background: none !important;
    color: #999 !important;
    margin-bottom: 10px !important;
}
.custome-select{  
    margin-bottom: 20px;
    font-family: var(--fontLato);
    font-weight: var(--font-weightRegular);
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: #484555 !important;
    width:100%;
    border: 1px solid #A5A5A5;
    border-radius: 5px;
    padding: 10px 22px 10px 15px;
    cursor: pointer;
    background: url('https://assets.ooki.io/assetsImage/dd_icon.svg') no-repeat 95% !important;
}

@media(max-width:576px){
    .custome-select{ 
        background: url('https://assets.ooki.io/assetsImage/dd_icon.svg') no-repeat 90% !important;
        margin-bottom: 10px;
    }  
  
}
@media(max-width:991px){
.form-control{
    color: #484555 !important;
    font-size:16px !important;
}
}
select::-ms-expand {
    display: none;
}
select {
    -webkit-appearance: none;
    appearance: none;
    padding-right: 26px !important;
}

@-moz-document url-prefix() {
    .ui-select {
        border: 1px solid #CCC;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
    }
    .ui-select select {
        width: 110%;
        background-position: right 30px center !important;
        border: none !important;
    }
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
    background-color: #50A8FC !important;
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
    border-radius: 50%;
    width: 35px !important;
    height: 35px !important;
}